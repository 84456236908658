import * as React from 'react';

import { Auth } from '@/containers/useAuth';
import { AlgoliaVideo } from '@urban/algolia';

export const useContentAccess = (video?: AlgoliaVideo) => {
  const { context } = Auth.useContainer();

  return React.useMemo(() => {
    if (!video) return false;
    if (video.price === 0) return true;
    return !!context?.subscription || !!context?.contents.includes(video.objectID);
  }, [context, video]);
};
