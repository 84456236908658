import React from 'react';
import classnames from 'classnames';

import { As, AsComponent, AsProps } from './As';

export const Small = React.forwardRef(
  <T extends AsComponent>({ className, ...rest }: AsProps<T>, ref: React.Ref<HTMLElement>) => (
    <As {...{ ref }} className={classnames('uppercase tracking-wider font-bold text-xs', className)} {...rest} />
  )
);
