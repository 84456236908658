import classnames from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';
import React from 'react';
import { useInterval } from 'react-use';

interface Props {
  className?: string;
}

export const Carousel: React.FC<React.PropsWithChildren<Props>> = ({ className, children }) => {
  const items = React.useMemo(() => {
    const items = React.Children.toArray(children);
    return items.length === 2 ? [...items, ...items] : items;
  }, [children]);

  const [hovered, setHovered] = React.useState(false);

  const [ref, emblaApi] = useEmblaCarousel({
    align: 'center',
    loop: true,
    draggable: true
  });

  const onNext = React.useCallback(() => emblaApi?.scrollNext(), [emblaApi]);

  const onMouseEnter = React.useCallback(() => setHovered(true), []);
  const onMouseLeave = React.useCallback(() => setHovered(false), []);

  useInterval(onNext, hovered ? null : 10000);

  return (
    <div className={classnames('overflow-hidden', className)} {...{ ref, onMouseEnter, onMouseLeave }}>
      <div className="-ml-8 flex h-auto flex-row [backface-visibility:hidden] [touch-action:pan-y] md:-ml-20">
        {items.map((item, index) => (
          <div key={index} className="relative min-w-0 max-w-7xl flex-[0_0_100%] overflow-hidden pl-8 md:pl-20">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
