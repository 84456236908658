import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { ValidationMessage } from '@/components/Form/ValidationMessage';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';

export const schema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.email.label)
  })
  .required();

export const ForgotPasswordPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const onSubmit = React.useCallback(
    async ({ email }: yup.InferType<typeof schema>) => new Promise((resolve) => setTimeout(resolve, 1000)),
    []
  );

  return (
    <Form {...{ onSubmit, schema }}>
      {({ submitting, submitSucceeded, submitError }) => (
        <div className="space-y-10">
          <h1 className="text-3xl font-light md:text-4xl">
            <FormattedMessage id={translations.pages.forgotPassword.title} />
          </h1>

          {!submitSucceeded ? (
            <>
              <div>
                <FormattedMessage id={translations.pages.forgotPassword.send.message} />
              </div>

              <div className="space-y-6">
                <ValidatedField
                  field={InputWithLabel}
                  id="email"
                  label={<FormattedMessage id={translations.fields.email.label} />}
                  placeholder={formatMessage({ id: translations.fields.email.placeholder })}
                  name="email"
                  type="email"
                  readOnly={submitting}
                />

                <ValidationMessage visible={!!submitError}>{submitError}</ValidationMessage>

                <LoadingButton appearance="primary" className="h-14 w-full" loading={submitting} type="submit">
                  <FormattedMessage id={translations.pages.forgotPassword.send.cta} />
                </LoadingButton>

                <div className="text-center">
                  <Link as={RouterLink} to="/login">
                    <FormattedMessage id={translations.pages.forgotPassword.send.remember} />
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <div>
              <FormattedMessage id={translations.pages.forgotPassword.confirm.message} />
            </div>
          )}
        </div>
      )}
    </Form>
  );
};
