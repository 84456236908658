/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentPlayback } from '../models/ContentPlayback';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ContentService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieves the content playback information.
   * @returns ContentPlayback Content playback information.
   * @throws ApiError
   */
  public getContentPlayback({
    contentId,
  }: {
    /**
     * The id of the content.
     */
    contentId: string,
  }): CancelablePromise<ContentPlayback> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/contents/{contentId}/playback',
      path: {
        'contentId': contentId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

}
