import flat from 'flat';
import * as yup from 'yup';

import { Language } from './language';

import englishMessage from './en.json';

export type LocaleTranslation = typeof englishMessage;

const messages: { [k in Language]?: LocaleTranslation } = {
  [Language.English]: englishMessage
};

const traverse = (obj: any, path = ''): any => {
  if (obj === null) return null;

  if (typeof obj !== 'object') return path;

  return Object.keys(obj).reduce<any>((result, key) => {
    result[key] = traverse(obj[key], path ? `${path}.${key}` : key);
    return result;
  }, {});
};

export const translations: LocaleTranslation = traverse(englishMessage);

yup.setLocale(translations.validation);

export const getTranslationMessages = (language: Language): any => flat(messages[language]);
