import classnames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from './As';

const styles = {
  primary: 'bg-[#363636] text-white hover:bg-[#363636F0] font-bold uppercase tracking-widest',
  secondary: 'bg-white text-[#363636] hover:bg-[#FFFFFFF0] font-bold uppercase tracking-widest'
};

export type Appearance = keyof typeof styles;

interface Props {
  appearance?: Appearance;
}

export const Button = React.forwardRef(
  <T extends AsComponent>(
    // @ts-ignore
    { as = 'button', appearance, className, ...props }: Props & AsProps<T>,
    ref: React.Ref<HTMLElement>
  ) => (
    <As
      {...{ as, ref }}
      className={classnames(
        'inline-flex items-center justify-center text-xs',
        !!appearance && styles[appearance],
        className
      )}
      {...props}
    />
  )
);
