import classnames from 'classnames';
import React from 'react';
import useSWR from 'swr';

import { Auth } from '@/containers/useAuth';
import { QueryParamConfig, decodeSingleQueryParam, useQueryParams } from '@/hooks/useQueryParams';
import { getVideos } from '@/services/videos';

import { Container } from '@/components/Container';
import { Select } from '@/components/Form/Select';
import { SelectItem } from '@/components/Form/Select/SelectItem';
import { Title } from '@/components/Title';
import { VideoItem } from '@/components/VideoGridItem';

const category: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined)
};

const config = { category };

export const LibraryPage: React.FC = () => {
  const { context } = Auth.useContainer();
  const [params, update] = useQueryParams({ config });

  const content = useSWR(
    {
      key: `getOwnedVideos`,
      ids: context?.subscription ? [] : context?.contents ?? [],
      subscription: context?.subscription,
      tag: params.category
    },
    ({ ids, subscription, tag }) =>
      ids.length || subscription ? getVideos({ ids, tags: tag ? [tag] : [] }) : undefined,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      keepPreviousData: true
    }
  );

  const categories = React.useMemo(() => {
    const all = { value: undefined, name: `All categories`, count: undefined };

    if (!content.data?.videos.facets?.tags) return [all];

    return [
      all,
      ...Object.entries(content.data.videos.facets.tags).map(([key, value]) => ({
        value: key,
        name: key,
        count: value
      }))
    ];
  }, [content]);

  return (
    <main className="space-y-8 px-10">
      <Container className="mt-12 space-y-8">
        <header className="flex items-center justify-between">
          <Title as="h2" size="h3" className="mb-4">
            My Library
          </Title>

          <Select
            placeholder="Select category"
            value={params.category}
            items={categories}
            searchable
            renderItem={({ props: { key, ...props }, item }: any) => (
              <SelectItem key={key} {...props}>
                <div className="flex items-center">
                  <span>{item.name}</span>
                  {!!item.count && (
                    <div className="text-[#6D6D6D] group-hover:text-white">
                      <span className="hidden px-2 md:inline-block">-</span>
                      {item.count}
                    </div>
                  )}
                </div>
              </SelectItem>
            )}
            containerClassName="w-96"
            onChange={(value) => update({ category: value?.toString() })}
          />
        </header>

        <ul className="grid gap-8 pb-16 md:grid-cols-2">
          {content.data?.videos.items.map((video) => (
            <li
              key={video.objectID}
              className={classnames('opacity-100 transition-opacity delay-100', {
                'pointer-events-none opacity-50': content.isLoading || content.isValidating
              })}
            >
              <VideoItem {...{ video }} />
            </li>
          ))}
        </ul>
      </Container>
    </main>
  );
};
