import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Auth } from '@/containers/useAuth';

import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { ValidationMessage } from '@/components/Form/ValidationMessage';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';

export const schema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.email.label),
    password: yup.string().required().label(translations.fields.password.label)
  })
  .required();

export const LoginPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { login } = Auth.useContainer();

  const onSubmit = React.useCallback(
    async ({ email, password }: yup.InferType<typeof schema>) => login({ email, password }),
    [login]
  );

  return (
    <Form {...{ onSubmit, schema }}>
      {({ submitting, submitError }) => (
        <div className="space-y-10">
          <h1 className="text-3xl font-light md:text-4xl">
            <FormattedMessage id={translations.pages.login.title} />
          </h1>

          <div className="space-y-6">
            <ValidatedField
              field={InputWithLabel}
              id="email"
              label={<FormattedMessage id={translations.fields.email.label} />}
              placeholder={formatMessage({ id: translations.fields.email.placeholder })}
              name="email"
              type="email"
              readOnly={submitting}
            />

            <ValidatedField
              field={InputWithLabel}
              id="password"
              label={<FormattedMessage id={translations.fields.password.label} />}
              placeholder={formatMessage({ id: translations.fields.password.placeholder })}
              name="password"
              type="password"
              readOnly={submitting}
            />

            <div className="mt-4 text-right">
              <Link as={RouterLink} to="/forgot-password" className="">
                <FormattedMessage id={translations.pages.login.forgot} />
              </Link>
            </div>

            <ValidationMessage visible={!!submitError}>{submitError}</ValidationMessage>

            <LoadingButton appearance="primary" className="h-14 w-full" loading={submitting} type="submit">
              <FormattedMessage id={translations.pages.login.cta} />
            </LoadingButton>
          </div>
        </div>
      )}
    </Form>
  );
};
