import { SearchClient, VideoQuery } from '@urban/algolia';
import memoizee from 'memoizee';

import { algolia, videoIndex } from './algolia';

const client = new SearchClient({ algolia });
const video = new VideoQuery({ index: videoIndex });

export const getPromotedVideos = memoizee(
  () => client.search({ videos: video.for({ available: false, promoted: true }) }),
  { max: 1000 * 60 * 60, normalizer: JSON.stringify.bind(JSON) }
);

export const getVideos = memoizee(
  ({ ids, free, tags, count }: { ids?: string[]; free?: boolean; tags?: string[]; count?: number } = {}) => {
    return client.search({
      videos: video.for({ available: true, objectIDs: ids, free, tags, facets: ['tags'], count })
    });
  },
  { max: 1000 * 60 * 60, normalizer: JSON.stringify.bind(JSON) }
);

export const getVideo = memoizee(
  async (videoId: string) => {
    const result = await client.search({ videos: video.for({ available: true, objectIDs: [videoId] }) });
    return result.videos.items[0];
  },
  { max: 1000 * 60 * 60, normalizer: JSON.stringify.bind(JSON) }
);
