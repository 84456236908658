import { MultipleQueriesQuery, SearchResponse } from '@algolia/client-search';

import { AlgoliaObjectWithAnalytics, AlgoliaResult, AlgoliaVideo } from '../types';

import { AlgoliaAnalyticsQueryParameters, AlgoliaQueryWithAnalytics } from './algolia-query-with-analytics';

export interface AlgoliaResultWithFacet<T> extends AlgoliaResult<T> {
  facets: SearchResponse['facets'];
}

export interface VideoQueryParameters extends AlgoliaAnalyticsQueryParameters {
  objectIDs?: string[];
  available?: boolean;
  promoted?: boolean;
  free?: boolean;
  tags?: string[];
  facets?: string[];
}

export class VideoQuery extends AlgoliaQueryWithAnalytics<
  VideoQueryParameters,
  AlgoliaResultWithFacet<AlgoliaObjectWithAnalytics<AlgoliaVideo>>
> {
  protected getUnixTimestamp(date: Date) {
    return Math.round(date.getTime() / 1000);
  }

  protected getAvailableFilter() {
    const now = this.getUnixTimestamp(new Date());

    return [`from < ${now}`, `to > ${now}`];
  }

  protected override getQuery({
    objectIDs,
    available,
    promoted,
    free,
    tags,
    facets,
    ...params
  }: VideoQueryParameters): MultipleQueriesQuery {
    const result = super.getQuery(params);

    return {
      ...result,
      params: {
        ...result.params,
        ...(facets && { facets }),
        numericFilters: [...(available ? this.getAvailableFilter() : []), ...(free ? [`price = 0`] : [])],
        facetFilters: [
          ...(objectIDs && objectIDs.length > 0 ? [objectIDs.map((objectID) => `objectID:${objectID}`)] : []),
          ...(promoted ? [`promoted:${promoted}`] : []),
          ...(tags && tags.length > 0 ? [tags.map((tag) => `tags:${tag}`)] : [])
        ]
      }
    };
  }

  protected override handler(
    response: SearchResponse<AlgoliaObjectWithAnalytics<AlgoliaVideo>>
  ): AlgoliaResultWithFacet<AlgoliaObjectWithAnalytics<AlgoliaVideo>> {
    const result = super.handler(response);

    return {
      ...result,
      facets: response.facets
    };
  }
}
