import React from 'react';

interface Props {
  amount: number;
  zero?: React.ReactNode;
}

export const Price: React.FC<Props> = ({ amount, zero = 'Free' }) => (
  <React.Fragment>{amount === 0 ? zero : `$${amount.toFixed(2)}`}</React.Fragment>
);
