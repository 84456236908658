import React from 'react';
import { Link } from 'react-router-dom';

import { Auth } from '@/containers/useAuth';

import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { Loading, LoadingIndicator, LoadingMessage } from '@/components/Loading';
import { Small } from '@/components/Small';
import { Title } from '@/components/Title';

export const CheckoutConfirmSubscriptionPage: React.FC = () => {
  const { context, reload } = Auth.useContainer();

  const success = !!context?.subscription;

  React.useEffect(() => {
    if (success) return;

    const timeout = setTimeout(reload, 2000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, success]);

  return (
    <Container className="flex flex-1 items-center justify-center">
      <main className="pt-20 pb-40 text-center">
        {success ? (
          <div className="space-y-10 border border-gray-200 p-10">
            <Title as="h1" size="h4" className="max-w-xl">
              You now have access to all of the videos on the platform.
            </Title>

            <Button as={Link} to="/library" appearance="primary" className="h-14 px-12">
              Browse videos
            </Button>
          </div>
        ) : (
          <Loading visible>
            <LoadingMessage>
              <LoadingIndicator className="h-6 w-6" />
              <Small as="div">Loading...</Small>
            </LoadingMessage>
          </Loading>
        )}
      </main>
    </Container>
  );
};
