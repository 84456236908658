/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InitiateResetPasswordRequest } from '../models/InitiateResetPasswordRequest';
import type { LoginWithPasswordRequest } from '../models/LoginWithPasswordRequest';
import type { LogoutRequest } from '../models/LogoutRequest';
import type { RefreshSessionRequest } from '../models/RefreshSessionRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { Session } from '../models/Session';
import type { ValidateResetPasswordRequest } from '../models/ValidateResetPasswordRequest';
import type { ValidateResetPasswordResponse } from '../models/ValidateResetPasswordResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthenticationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Authenticates the user.
   * @returns Session Authentication successful.
   * @throws ApiError
   */
  public loginWithPassword({
    requestBody,
  }: {
    requestBody: LoginWithPasswordRequest,
  }): CancelablePromise<Session> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/password/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Invalidates the current session.
   * @returns void
   * @throws ApiError
   */
  public logout({
    requestBody,
  }: {
    requestBody: LogoutRequest,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/logout',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Refreshes the session.
   * @returns Session Session refreshed.
   * @throws ApiError
   */
  public renew({
    requestBody,
  }: {
    requestBody: RefreshSessionRequest,
  }): CancelablePromise<Session> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/refresh',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Initiate reset password.
   * @returns any Request has been accepted and it will be processed.
   * @throws ApiError
   */
  public initiateResetPassword({
    requestBody,
  }: {
    requestBody: InitiateResetPasswordRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/password/send',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Validate reset password token.
   * @returns ValidateResetPasswordResponse Session refreshed.
   * @throws ApiError
   */
  public validateResetPasswordToken({
    requestBody,
  }: {
    requestBody: ValidateResetPasswordRequest,
  }): CancelablePromise<ValidateResetPasswordResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/password/validate',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error.`,
      },
    });
  }

  /**
   * Reset password.
   * @returns any Password reset.
   * @throws ApiError
   */
  public resetPassword({
    requestBody,
  }: {
    requestBody: ResetPasswordRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/password/reset',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error.`,
      },
    });
  }

}
