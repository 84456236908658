import MuxVideo from '@mux/mux-video-react';
import React from 'react';

export const BackgroundVideo = React.forwardRef<HTMLVideoElement, React.ComponentProps<typeof MuxVideo>>(
  ({ playbackId, ...props }, ref) => (
    <MuxVideo
      {...props}
      {...{ ref, playbackId }}
      poster={`https://image.mux.com/${playbackId}/thumbnail.jpg`}
      playsInline
    />
  )
);
