import classnames from 'classnames';
import React from 'react';

interface Props {
  visible: boolean;
  className?: string;
}

export const ValidationMessage: React.FC<React.PropsWithChildren<Props>> = ({
  visible,
  className,
  children,
  ...rest
}) => {
  return (
    <div
      className={classnames('text-left text-red-600', visible ? 'visible mt-1 opacity-100' : 'hidden', className)}
      role="alert"
      {...rest}
    >
      {children}
    </div>
  );
};
