import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import './style.css';

import { Auth } from '@/containers/useAuth';
import { LastSession } from '@/containers/useLastSession';
import { getTranslationMessages, Language } from '@/locales';

import { App } from './App';

const root = createRoot(document.getElementById('app')!);

root.render(
  <IntlProvider
    defaultRichTextElements={{ br: (<br />) as any }}
    locale={Language.English}
    messages={getTranslationMessages(Language.English)}
  >
    <BrowserRouter>
      <Auth.Provider>
        <LastSession.Provider>
          <App />
        </LastSession.Provider>
      </Auth.Provider>
    </BrowserRouter>
  </IntlProvider>
);
