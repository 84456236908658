import React from 'react';
import { useLocalStorage } from 'react-use';
import useSWR from 'swr';
import { createContainer } from 'unstated-next';

import { getVideos } from '@/services/videos';
import { useLocation } from 'react-router';

const useCart = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);

  const [items = [], setItems] = useLocalStorage<string[]>('cart', [], {
    raw: false,
    serializer: JSON.stringify,
    deserializer: JSON.parse
  });

  const cart = useSWR(
    { key: `getCart`, items },
    ({ items }) => (items?.length ? getVideos({ ids: items }) : undefined),
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  React.useEffect(() => {
    setIsOpen(false);
  }, [setIsOpen, location]);

  return React.useMemo(
    () => ({
      isOpen,
      loading: cart.isLoading || cart.isValidating,
      total: cart.data?.videos.items.reduce((total, video) => total + video.price, 0) ?? 0,
      items: cart.data?.videos.items ?? [],
      add: (videoIds: string[]) => {
        setItems(Array.from(new Set([...items, ...videoIds])));
        setIsOpen(true);
      },
      remove: (videoIds: string[]) => setItems(items.filter((item) => !videoIds.includes(item))),
      open: () => setIsOpen(true),
      close: () => setIsOpen(false)
    }),
    [cart, items, setItems, setIsOpen]
  );
};

export const Cart = createContainer(useCart);
