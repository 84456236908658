import { MultipleQueriesQuery, SearchResponse } from '@algolia/client-search';

import { AlgoliaResult, UnwrapAlgoliaResult } from '../types';

export interface AlgoliaQueryOptions {
  index: string;
}

export interface AlgoliaQueryParameters {
  query?: string;
  page?: number;
  count?: number;
}

export interface AlgoliaQueryResolver<R extends AlgoliaResult<unknown>> {
  query: MultipleQueriesQuery;
  handler: (response: SearchResponse<UnwrapAlgoliaResult<R>>) => R;
}

export type GetResolver<AQ extends AlgoliaQuery<any, any>> = AQ extends AlgoliaQuery<any, infer R>
  ? AlgoliaQueryResolver<R>
  : never;

export abstract class AlgoliaQuery<P extends AlgoliaQueryParameters, R extends AlgoliaResult<unknown>> {
  constructor(protected options: AlgoliaQueryOptions) {}

  for(params: P): AlgoliaQueryResolver<R> {
    return {
      query: this.getQuery(params),
      handler: this.handler.bind(this)
    };
  }

  protected getQuery({ query, page = 1, count = 100 }: P): MultipleQueriesQuery {
    return {
      indexName: this.options.index,
      query,
      params: { page: page - 1, hitsPerPage: count }
    };
  }

  protected handler(response: SearchResponse<UnwrapAlgoliaResult<R>>): R {
    return {
      count: response.nbHits,
      items: response.hits
    } as R;
  }
}
