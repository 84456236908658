import classnames from 'classnames';
import React from 'react';
import useSWR from 'swr';

import { QueryParamConfig, decodeSingleQueryParam, useQueryParams } from '@/hooks/useQueryParams';
import { getPromotedVideos, getVideos } from '@/services/videos';

import { Carousel } from '@/components/Carousel';
import { Container } from '@/components/Container';
import { Select } from '@/components/Form/Select';
import { SelectItem } from '@/components/Form/Select/SelectItem';
import { PromotedVideo } from '@/components/PromotedVideo';
import { Title } from '@/components/Title';
import { VideoItem } from '@/components/VideoGridItem';

const category: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined)
};

const config = { category };

export const ExplorePage: React.FC = () => {
  const [params, update] = useQueryParams({ config });

  const promoted = useSWR({ key: `getPromotedVideos` }, () => getPromotedVideos(), {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    keepPreviousData: true
  });

  const content = useSWR(
    { key: `getVideos`, tag: params.category },
    ({ tag }) => getVideos({ tags: tag ? [tag] : [] }),
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      keepPreviousData: true
    }
  );

  const categories = React.useMemo(() => {
    const all = { value: undefined, name: `All categories`, count: undefined };

    if (!content.data?.videos.facets?.tags) return [all];

    return [
      all,
      ...Object.entries(content.data.videos.facets.tags).map(([key, value]) => ({
        value: key,
        name: key,
        count: value
      }))
    ];
  }, [content]);

  return (
    <main className="">
      <Container>
        <Title as="h2" size="h3" className="mt-12 mb-8">
          Explore
        </Title>
      </Container>

      {!!promoted.data?.videos?.items?.length && (
        <Carousel>
          {promoted.data.videos.items.map(({ objectID: id, title, image, duration, tags }) => (
            <PromotedVideo key={id} {...{ id, title, image, duration, tags }} />
          ))}
        </Carousel>
      )}

      <Container className="mt-12 space-y-8">
        <header className="items-center justify-between md:flex">
          <Title as="h2" size="h3" className="mb-4">
            All videos
          </Title>

          <Select
            placeholder="Select category"
            value={params.category}
            items={categories}
            searchable
            renderItem={({ props: { key, ...props }, item }: any) => (
              <SelectItem key={key} {...props}>
                <div className="flex items-center">
                  <span>{item.name}</span>
                  {!!item.count && (
                    <div className="text-[#6D6D6D] group-hover:text-white">
                      <span className="px-2">-</span>
                      {item.count}
                    </div>
                  )}
                </div>
              </SelectItem>
            )}
            containerClassName="w-full md:w-96"
            onChange={(value) => update({ category: value?.toString() })}
          />
        </header>

        <ul className="grid gap-8 pb-24 md:grid-cols-2">
          {content.data?.videos.items.map((video) => (
            <li
              key={video.objectID}
              className={classnames('opacity-100 transition-opacity delay-100', {
                'pointer-events-none opacity-50': content.isLoading || content.isValidating
              })}
            >
              <VideoItem {...{ video }} />
            </li>
          ))}
        </ul>
      </Container>
    </main>
  );
};
