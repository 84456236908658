import { PlayCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';

import { formatDuration } from '@/utils/duration';
import { AlgoliaVideo } from '@urban/algolia';

import { useContentAccess } from '@/hooks/useContentAccess';

import { LastSession } from '@/containers/useLastSession';
import { ImageBackdrop } from './ImageBackdrop';
import { Small } from './Small';
import { Tags } from './Tags';
import { Title } from './Title';

interface Props {
  video: AlgoliaVideo;
}

export const VideoItem: React.FC<Props> = ({ video }) => {
  const lastSession = LastSession.useContainer();
  const owned = useContentAccess(video);

  return (
    <Link to={`/videos/${video.objectID}`} className="group relative block">
      <div className="relative">
        <ImageBackdrop className="overflow-hidden">
          <img
            src={video.image}
            className="aspect-video object-cover transition-transform duration-1000 group-hover:scale-105"
          />
        </ImageBackdrop>

        <div className="absolute top-1/2 left-1/2 flex h-12 w-12 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-[#1B1B1B] text-white opacity-0 transition-opacity group-hover:opacity-100">
          <PlayCircleIcon className="-m-2 h-20 w-20" />
        </div>

        <div className="absolute top-4 left-4 flex items-center space-x-2">
          {!video.price ? (
            <Small as="p" className="bg-[#1B1B1B] py-3 px-4 text-white">
              Free
            </Small>
          ) : owned ? (
            <Small as="p" className="bg-[#1B1B1B] py-3 px-4 text-white">
              Owned
            </Small>
          ) : null}

          {!!lastSession && video.createdAt > lastSession.getTime() / 1000 && (
            <Small as="p" className="bg-[#1B1B1B] py-3 px-4 text-white">
              New
            </Small>
          )}
        </div>
      </div>

      <div className="space-y-2 py-4">
        <Title as="h2" size="h4">
          {video.title}
        </Title>

        <Tags>{video.tags}</Tags>

        <p className="text-[#6D6D6D]">{formatDuration(video.duration, '<1m')}</p>
      </div>
    </Link>
  );
};
