import classnames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from '@/components/As';

export type Props<T extends AsComponent> = AsProps<T> & {
  invalid?: boolean;
};

export const Input = React.forwardRef(function Input<T extends AsComponent = 'input'>(
  // @ts-ignore
  { as = 'input', invalid, ...props }: React.PropsWithChildren<Props<T>>,
  ref: React.Ref<any>
) {
  return (
    // @ts-ignore
    <As
      {...{ as, ref }}
      {...props}
      className={classnames(
        'form-input block h-14 w-full border border-solid px-4 py-4 leading-6 placeholder:text-[#6D6D6D]',
        { 'border-[#DBDBDB]': !invalid, 'border-red-500': invalid },
        props.readOnly && 'bg-[#F3F0E8]',
        props.className
      )}
    />
  );
});
