import MuxPlayer from '@mux/mux-player-react';
import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router';
import useSWR from 'swr';

import { apiClient } from '@/services/api';
import { getVideo, getVideos } from '@/services/videos';
import { formatDuration } from '@/utils/duration';

import { Auth } from '@/containers/useAuth';
import { Cart } from '@/containers/useCart';
import { useContentAccess } from '@/hooks/useContentAccess';

import { BackgroundVideo } from '@/components/BackgroundVideo';
import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { ImageBackdrop } from '@/components/ImageBackdrop';
import { Price } from '@/components/Price';
import { Tags } from '@/components/Tags';
import { Title } from '@/components/Title';
import { VideoItem } from '@/components/VideoGridItem';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';

export const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' as any
    });
  }, [pathname]);

  return null;
};

export const VideoPage: React.FC = () => {
  const { context } = Auth.useContainer();
  const { add, items } = Cart.useContainer();
  const { videoId } = useParams<{ videoId: string }>();

  const video = useSWR({ key: `getVideo`, videoId }, ({ videoId }) => (videoId ? getVideo(videoId) : undefined), {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    keepPreviousData: true
  });

  const owned = useContentAccess(video.data);

  const related = useSWR(
    { key: `getRelatedVideos`, videoIds: video?.data?.related ?? [], tag: video?.data?.tags?.[0] },
    ({ videoIds, tag }) =>
      videoIds.length > 0 ? getVideos({ ids: videoIds }) : tag ? getVideos({ tags: [tag], count: 4 }) : undefined,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      keepPreviousData: true
    }
  );

  const playback = useSWR(
    { key: `getPlayback`, contentId: video?.data?.objectID, owned },
    ({ contentId, owned }) => (contentId && owned ? apiClient.content.getContentPlayback({ contentId }) : undefined),
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  if (!videoId) return <Navigate to="/" />;

  return (
    <>
      <ScrollToTop />

      <main className="-mt-28 space-y-16">
        <section className="relative flex h-screen flex-col justify-center overflow-hidden bg-gray-100 pt-20">
          {playback.data?.playbackId ? (
            <MuxPlayer
              streamType="on-demand"
              poster={`https://image.mux.com/${video.data?.teaserPlaybackId}/thumbnail.jpg`}
              playbackId={playback.data.playbackId}
              tokens={{ playback: playback.data.token }}
              metadata={{
                video_id: video.data?.objectID,
                video_title: video.data?.title,
                viewer_user_id: context?.profile?.id
              }}
              className="absolute inset-0"
            />
          ) : (
            <>
              <ImageBackdrop className="absolute inset-0 cursor-pointer overflow-hidden">
                <BackgroundVideo
                  playbackId={video.data?.teaserPlaybackId}
                  metadata={{
                    video_id: video.data?.objectID,
                    video_title: video.data?.title,
                    viewer_user_id: context?.profile?.id
                  }}
                  autoPlay="any"
                  loop
                  className="h-full w-full object-cover"
                />
              </ImageBackdrop>

              <Container className="relative w-full text-white">
                <Title as="h1" size="h2" className="mb-6">
                  {video?.data?.title}
                </Title>

                <Tags className="mb-6 flex items-center">{video?.data?.tags}</Tags>

                <p className="mb-6">{formatDuration(video?.data?.duration ?? 0, '<1m')}</p>

                <p className="mb-6 max-w-2xl">{video?.data?.description}</p>

                {!owned &&
                  (items.some((item) => item.objectID === videoId) ? (
                    <Button appearance="secondary" className="h-14 px-12" onClick={() => add([videoId])}>
                      <CheckBadgeIcon className="mr-4 h-6 w-6" />
                      Added to cart
                    </Button>
                  ) : (
                    <Button appearance="secondary" className="h-14 px-12" onClick={() => add([videoId])}>
                      Full-length video for <Price amount={video.data?.price ?? 0} />
                    </Button>
                  ))}
              </Container>
            </>
          )}
        </section>

        {!!related?.data?.videos?.items.length && (
          <section>
            <Container className="space-y-8">
              <Title as="h2" size="h4">
                {video?.data?.related?.length ? `Related videos` : `More in ${video?.data?.tags?.[0]}`}
              </Title>

              <ul className="grid gap-8 pb-24 md:grid-cols-2">
                {related.data.videos.items.map((video) => (
                  <li key={video.objectID} className="opacity-100 transition-opacity delay-100">
                    <VideoItem {...{ video }} />
                  </li>
                ))}
              </ul>
            </Container>
          </section>
        )}
      </main>
    </>
  );
};
