import { ApiClient, OpenAPI } from '@urban/platform-api-sdk';

import { API_URL } from '@/config';

export const apiClient = new ApiClient({ BASE: API_URL });

export const updateAuthorization = (token?: string) => {
  OpenAPI.TOKEN = token;
};

export const delay = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

interface Context {
  user: {
    id: string;
    email: string;
    name: string;
  };
  cart?: {
    id: string;
    total: number;
    items: {
      id: string;
      image: string;
      name: string;
      description: string;
      price: number;
    }[];
  };
}

export const getContext = async (): Promise<Context> => {
  await delay(500);

  return {
    user: {
      id: 'asd',
      email: 'botond.veress@gmail.com',
      name: 'Botond Veress'
    },
    cart: {
      id: 'cart',
      total: 64.99,
      items: Array.from({ length: 3 }).map((_, index) => ({
        id: (index + 1).toString(),
        image:
          'https://images.unsplash.com/photo-1544198365-f5d60b6d8190?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
        name: 'Dunk low light bone',
        description: '1h 30m',
        price: 14.99
      }))
    }
  };
};
