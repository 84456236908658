import React from 'react';

import { Container } from '@/components/Container';
import { Loading, LoadingIndicator, LoadingMessage } from '@/components/Loading';
import { Small } from '@/components/Small';
import { apiClient } from '@/services/api';

export const OrderHistoryPage: React.FC = () => {
  React.useEffect(() => {
    apiClient.purchase
      .getBillingPortal({ redirectUrl: window.origin })
      .then(({ url }) => (window.location.href = url))
      .catch(() => null);
  }, []);

  return (
    <Container className="flex flex-1 items-center justify-center">
      <Loading visible className="">
        <LoadingMessage>
          <LoadingIndicator className="h-6 w-6" />
          <Small as="div">Loading...</Small>
        </LoadingMessage>
      </Loading>
    </Container>
  );
};
