export interface AlgoliaAnalytics {
  index: string;
  queryID: string;
}

export interface AlgoliaAnalyticsWithPosition extends AlgoliaAnalytics {
  position: number;
}

export type AlgoliaObjectWithAnalytics<T> = T & {
  analytics?: string;
};

export const encodeAnalyticsValue = ({ index, queryID, position }: AlgoliaAnalyticsWithPosition) => {
  return [index, queryID, position].join(':');
};

export const decodeAnalyticsValue = (value: string): AlgoliaAnalyticsWithPosition => {
  const [index, queryID, position] = value.split(':');
  return { index, queryID, position: parseInt(position) };
};
