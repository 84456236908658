import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/react-dom';
import { Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import {
  GetItemPropsOptions,
  UseComboboxProps,
  UseComboboxState,
  UseComboboxStateChangeOptions,
  useCombobox
} from 'downshift';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { compareTwoStrings } from 'string-similarity';

import { Input } from '../Input';

import { SelectItem } from './SelectItem';

export interface Item<T> {
  value: T;
  name: string;
  disabled?: boolean;
}

export interface RenderItemsProps<I> {
  items: I[];
  inputValue?: string;
  children: React.ReactNode;
}

export interface RenderItemProps<I> {
  props: any;
  item: I;
}

export interface Props<T, I = Item<T>> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  value?: T | T[] | null;
  items: I[];
  searchable?: boolean;
  editable?: boolean;
  multiple?: boolean;
  invalid?: boolean;
  getValue?(item: I): T;
  getDisplayName?(item: I): string;
  getInputValue?(isOpen: boolean, inputValue: string, displayName: string): string;
  getSelectedDisplayName?(props: { inputValue: string; selectedItems: I[] }): string;
  renderItem?(props: RenderItemProps<I>): React.ReactNode;
  renderItems?(props: RenderItemsProps<I>): React.ReactNode;
  containerClassName?: string;
  inputClassName?: string;
  contentClassName?: string;
  dropdownClassName?: string;
  itemsClassName?: string;
  placementStrategy?: 'absolute' | 'fixed';
  onInputValueChange?(inputValue: string): any;
  onChange?(value: T[] | T | null): any;
  getSearchableAttributes?(item: I): string;
  stateReducer?(
    state: UseComboboxState<I>,
    { type, changes }: UseComboboxStateChangeOptions<I>
  ): Partial<UseComboboxState<I>>;
}

export function Select<T, I extends Item<any>>(props: React.PropsWithChildren<Props<T, I>>) {
  const {
    getValue = (item) => (item ? item.value : null),
    getDisplayName = (item) => (item ? item.name : null),
    getSelectedDisplayName = ({ selectedItems }) => selectedItems.map(getDisplayName).join(', ') || '',
    getSearchableAttributes = (item) => item.name,
    getInputValue = (isOpen, inputValue, displayName) => (isOpen ? inputValue || '' : displayName),
    renderItem = ({ props: { key, ...props }, item }) => (
      <SelectItem key={key} {...props}>
        {getDisplayName(item)}
      </SelectItem>
    ),
    renderItems = ({ children }) => children,
    stateReducer: propStateReducer = (_, { changes }) => changes,
    placementStrategy = 'absolute'
  } = props;

  const selectedValues = React.useMemo(() => {
    // if (props.value == null) return [];
    if (Array.isArray(props.value)) return props.value;

    return [props.value];
  }, [props.value]);

  const selectedItems = React.useMemo(
    () =>
      selectedValues.map((value) => props.items.find((item) => isEqual(getValue(item), value))!).filter((item) => item),
    [selectedValues, props.items, getValue]
  );

  const stateReducer = React.useCallback(
    (state: UseComboboxState<I>, { type, changes }: UseComboboxStateChangeOptions<I>): Partial<UseComboboxState<I>> => {
      switch (type) {
        case useCombobox.stateChangeTypes.FunctionOpenMenu: {
          if (props.multiple) return propStateReducer(state, { type, changes });

          return propStateReducer(state, {
            type,
            changes: {
              ...changes,
              highlightedIndex:
                selectedValues.length > 0
                  ? props.items.findIndex((item) => isEqual(getValue(item), selectedValues[0]))!
                  : -1
            }
          });
        }
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return propStateReducer(state, {
            type,
            changes: {
              ...changes,
              isOpen: !!props.multiple,
              highlightedIndex: state.highlightedIndex,
              inputValue: '',
              selectedItem: changes.selectedItem
            }
          });
        case useCombobox.stateChangeTypes.InputBlur:
          return propStateReducer(state, { type, changes: { ...changes, inputValue: '' } });
        default:
          return propStateReducer(state, { type, changes });
      }
    },
    [selectedValues, props.multiple, props.items, propStateReducer, getValue]
  );

  const filter = React.useCallback(
    (items: I[], input: string) => {
      const normalizeName = (value: string) => value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      const normalizedInput = input && input.length > 0 ? normalizeName(input) : null;

      if (!normalizedInput || !props.searchable) return items.map((item, index) => ({ item, index }));

      const normalizeIndex = (index: number) => (index < 0 ? Infinity : index);

      return items
        .map((item, originalIndex) => {
          const normalizedName = normalizeName(getSearchableAttributes(item));
          const similarity = compareTwoStrings(normalizedInput, normalizedName);

          return { item, normalizedName, similarity, index: normalizedName.indexOf(normalizedInput), originalIndex };
        })
        .filter((item) => item.index >= 0 || item.similarity > 0.8)
        .sort((one, two) => {
          const indexDifference = normalizeIndex(one.index) - normalizeIndex(two.index);

          // prioritize exact searches first
          if (indexDifference !== 0) return indexDifference;

          return two.similarity - one.similarity;
        })
        .map(({ item, originalIndex }) => ({ item, index: originalIndex }));
    },
    [props.searchable, getSearchableAttributes]
  );

  const removeValue = React.useCallback(
    (selectedValue: T) => props.onChange?.(selectedValues.filter((value) => value !== selectedValue) as any),
    [selectedValues, props]
  );

  const addValue = React.useCallback(
    (selectedValue: T) => props.onChange?.([...selectedValues, selectedValue] as any),
    [selectedValues, props]
  );

  const updateValues = React.useCallback((selectedValues: T | T[] | null) => props.onChange?.(selectedValues), [props]);

  const renderAndFilterItems = React.useCallback(
    (
      inputValue: string,
      items: I[],
      selectedItems: I[],
      highlightedIndex: number | null,
      getItemProps: (options: GetItemPropsOptions<I>) => any
    ) => {
      const filteredItems = filter(items, inputValue || '');

      const children = filteredItems.map(({ item, index }) =>
        renderItem({
          props: {
            key: index,
            active: highlightedIndex === index,
            isSelected: !!item && selectedItems.some((selectedItem) => isEqual(getValue(selectedItem), getValue(item))),
            disabled: item.disabled,
            ...getItemProps({ item, index })
          },
          item
        })
      );

      return renderItems({ items: filteredItems.map(({ item }) => item), inputValue, children });
    },
    [filter, renderItem, getValue, renderItems]
  );

  const onSelectedItemChange = React.useCallback(
    ({ selectedItem }: Parameters<NonNullable<UseComboboxProps<I>['onSelectedItemChange']>>[0]) => {
      if (!selectedItem) return;

      const selectedValue = getValue(selectedItem);

      if (!props.multiple) return updateValues(selectedValue);

      if (selectedValues.some((value) => isEqual(value, selectedValue))) {
        return removeValue(selectedValue);
      }

      return addValue(selectedValue);
    },
    [getValue, updateValues, selectedValues, removeValue, addValue, props.multiple]
  );

  const onInputValueChange = React.useCallback(
    ({ inputValue }: Parameters<NonNullable<UseComboboxProps<I>['onInputValueChange']>>[0]) =>
      props.onInputValueChange?.(inputValue!),
    [props]
  );

  const input = React.useRef<HTMLInputElement>();

  const onIsOpenChange = React.useCallback(
    ({ isOpen }: Parameters<NonNullable<UseComboboxProps<I>['onIsOpenChange']>>[0]) => {
      if (!isOpen && input.current) input.current.blur();
    },
    []
  );

  const {
    isOpen,
    inputValue,
    getMenuProps,
    getComboboxProps,
    getToggleButtonProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    openMenu
  } = useCombobox({
    id: props.id,
    inputId: props.id,
    itemToString: (item) => (item ? item.name : ''),
    items: props.items,
    stateReducer,
    onIsOpenChange,
    onInputValueChange,
    onSelectedItemChange,
    selectedItem: null
  });

  const value = React.useMemo(() => {
    return getInputValue(
      isOpen,
      inputValue,
      getSelectedDisplayName({
        selectedItems,
        inputValue: inputValue || ''
      })
    );
  }, [getInputValue, isOpen, inputValue, getSelectedDisplayName, selectedItems]);

  const onFocus = React.useCallback(() => !isOpen && openMenu(), [isOpen, openMenu]);

  const floating = useFloating({
    placement: 'bottom-start',
    strategy: placementStrategy,
    middleware: [
      offset(4),
      flip(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`
          });
        }
      })
    ]
  });

  React.useEffect(() => {
    if (floating.refs.reference.current && floating.refs.floating.current) {
      return autoUpdate(floating.refs.reference.current, floating.refs.floating.current, floating.update);
    }
  }, [isOpen, floating.update, floating.refs.reference, floating.refs.floating]);

  return (
    <div {...getComboboxProps({ className: classnames('relative', props.containerClassName) })}>
      <div
        ref={floating.reference}
        className={classnames('relative h-full w-full cursor-default text-left', props.contentClassName)}
      >
        <Input
          {...getInputProps({
            type: 'text',
            placeholder: props.placeholder,
            onFocus,
            value,
            readOnly: props.readOnly || !(props.editable || props.searchable),
            disabled: props.disabled || props.readOnly,
            className: classnames(
              { 'cursor-default': props.readOnly || !(props.editable || props.searchable) },
              props.className
            ),
            // TODO
            ref: input as any
          })}
          invalid={props.invalid}
        />

        <button
          {...getToggleButtonProps({
            type: 'button',
            disabled: props.disabled || props.readOnly,
            className: 'absolute inset-y-0 right-0 flex items-center pr-2'
          })}
        >
          <ChevronUpDownIcon className="h-6 w-6 text-[#003F2E4D]" aria-hidden="true" />
        </button>
      </div>

      <div
        ref={floating.floating}
        style={{
          position: floating.strategy,
          top: floating.y ?? undefined,
          left: floating.x ?? undefined
        }}
        className="z-[1]"
      >
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={isOpen}
          afterLeave={() => props.onInputValueChange?.('')}
        >
          <ul
            className="max-h-60 overflow-y-auto rounded border border-solid border-[#003F2E30] bg-white py-1 shadow-lg"
            {...getMenuProps({}, { suppressRefError: true })}
          >
            {renderAndFilterItems(inputValue || '', props.items, selectedItems, highlightedIndex, getItemProps)}
          </ul>
        </Transition>
      </div>
    </div>
  );
}
