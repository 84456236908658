import { SearchResponse, MultipleQueriesQuery } from '@algolia/client-search';
import { AlgoliaObjectWithAnalytics, AlgoliaResult, encodeAnalyticsValue, UnwrapAlgoliaResult } from '../types';

import { AlgoliaQuery, AlgoliaQueryParameters } from './algolia-query';

export interface AlgoliaAnalyticsQueryParameters extends AlgoliaQueryParameters {
  analytics?: boolean;
}

export abstract class AlgoliaQueryWithAnalytics<
  P extends AlgoliaAnalyticsQueryParameters,
  R extends AlgoliaResult<AlgoliaObjectWithAnalytics<unknown>>
> extends AlgoliaQuery<P, R> {
  protected override getQuery(options: P): MultipleQueriesQuery {
    const result = super.getQuery(options);

    return {
      ...result,
      params: {
        ...result.params,
        clickAnalytics: options.analytics ?? false
      }
    };
  }

  protected override handler(response: SearchResponse<UnwrapAlgoliaResult<R>>): R {
    const result = super.handler(response);

    return {
      ...result,
      items: result.items.map((item, index) => ({
        ...item,
        analyticsContext:
          response.queryID &&
          encodeAnalyticsValue({
            index: this.options.index,
            queryID: response.queryID!,
            position: response.page * response.hitsPerPage + index + 1
          })
      }))
    };
  }
}
