import classnames from 'classnames';
import React from 'react';

import { As, AsComponent } from '@/components/As';

import { Input, Props as InputProps } from './Input';

export type Props<T extends AsComponent> = InputProps<T> & {
  id?: string;
};

export const InputWithLabel = React.forwardRef(function InputWithLabel<T extends AsComponent>(
  //@ts-ignore
  { as = Input, label, subLabel, className, ...props }: Props<T>,
  ref: React.Ref<any>
) {
  return (
    <div className={classnames('flex flex-col', className)}>
      <label className="text-xs font-bold uppercase leading-5 tracking-widest text-[#1B1B1B]" htmlFor={props.id}>
        {label}
      </label>

      <As {...props} {...{ as, ref }} className="mt-2" />
    </div>
  );
});
