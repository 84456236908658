import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { formatDuration } from '@/utils/duration';
import { Button } from './Button';
import { ImageBackdrop } from './ImageBackdrop';
import { Tags } from './Tags';
import { Title } from './Title';

interface Props {
  id: string;
  title: string;
  image: string;
  duration: number;
  className?: string;
}

export const PromotedVideo = React.forwardRef<HTMLDivElement, Props>(
  ({ id, title, image, duration, className }, ref) => (
    <div {...{ ref }} className={classnames('md:aspect-16/9 relative flex overflow-hidden py-10', className)}>
      <ImageBackdrop className="absolute inset-0">
        <img src={image} className="h-full w-full object-cover" />
      </ImageBackdrop>

      <div className="relative flex flex-1 flex-col justify-center space-y-4 p-4 text-white md:space-y-6 md:p-24">
        <Title as="h1" size="h3">
          {title}
        </Title>

        <Tags className="flex items-center">{['Bone', 'Upper membrane removal']}</Tags>

        <p className="max-w-3xl">{formatDuration(duration, '<1m')}</p>

        <div>
          <Button as={Link} to={`/videos/${id}`} appearance="secondary" className="h-14 w-full px-24 md:w-auto">
            Learn more
          </Button>
        </div>
      </div>
    </div>
  )
);
