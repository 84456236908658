import classnames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

export const Container: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <div {...props} className={classnames('mx-auto max-w-7xl px-4 md:px-10', props.className)} />
);
