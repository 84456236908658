import React from 'react';
import classnames from 'classnames';

import { As, AsComponent, AsProps } from './As';

type Props<T extends AsComponent> = AsProps<T> & {
  size: 'h1' | 'h2' | 'h3' | 'h4';
};

export const Title = React.forwardRef(
  <T extends AsComponent>({ size, className, ...rest }: Props<T>, ref: React.Ref<HTMLElement>) => (
    <As
      {...{ ref }}
      className={classnames(
        'font-light leading-tight',
        { 'text-6xl': size === 'h1', 'text-5xl': size === 'h2', 'text-4xl': size === 'h3', 'text-3xl': size === 'h4' },
        className
      )}
      {...rest}
    />
  )
);
