export const formatDuration = (duration: number, fallback: string) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);

  const formattedHours = hours > 0 ? `${hours}h` : undefined;
  const formattedMinutes = minutes > 0 ? `${minutes}m` : undefined;

  if (formattedHours && formattedMinutes) return `${formattedHours} ${formattedMinutes}`;

  return formattedHours ?? formattedMinutes ?? fallback;
};
