import { Menu, Transition } from '@headlessui/react';
import { ShoppingCartIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { Link, NavLink, Navigate, Route, Routes, useMatch } from 'react-router-dom';

import { Auth } from '@/containers/useAuth';
import { Cart } from '@/containers/useCart';
import { formatDuration } from '@/utils/duration';

import { Logo } from '@/components/Logo';
import { Price } from '@/components/Price';
import { Small } from '@/components/Small';

import { CheckoutPage } from '@/pages/Checkout';
import { CheckoutConfirmContentPage } from '@/pages/CheckoutConfirmContent';
import { CheckoutConfirmSubscriptionPage } from '@/pages/CheckoutConfirmSubscription';
import { ExplorePage } from '@/pages/ExplorePage';
import { ForgotPasswordPage } from '@/pages/ForgotPasswordPage';
import { LibraryPage } from '@/pages/LibraryPage';
import { LoginPage } from '@/pages/LoginPage';
import { OrderHistoryPage } from '@/pages/OrderHistoryPage';
import { PricingPage } from '@/pages/PricingPage';
import { ResetPasswordPage } from '@/pages/ResetPasswordPage';
import { VideoPage } from '@/pages/VideoPage';
import { useClickAway } from 'react-use';
import { Loading, LoadingIndicator, LoadingMessage } from './components/Loading';

const CartTooltip: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { loading, total, items, remove, isOpen, close } = Cart.useContainer();

  const ref = React.useRef<HTMLElement>(null);

  useClickAway(ref, () => close());

  return (
    <div>
      {children}

      <Transition
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <aside
          {...{ ref }}
          className="absolute right-0 top-full z-10 mt-2 w-80 origin-top-right space-y-4 rounded bg-white p-4 text-[#1B1B1B] shadow ring-1 ring-[#1B1B1B] ring-opacity-5 md:w-96"
        >
          {loading ? (
            <Loading visible>
              <LoadingMessage>
                <LoadingIndicator className="h-6 w-6" />
                <Small as="div">Loading...</Small>
              </LoadingMessage>
            </Loading>
          ) : (
            <>
              <Small as="p">{items.length > 0 ? `Your cart` : `Your cart is empty.`}</Small>

              {total > 0 ? (
                <>
                  <ul className="space-y-4">
                    {items.map((item) => (
                      <li key={item.objectID} className="group flex items-center space-x-4">
                        <div className="relative w-12 flex-shrink-0">
                          <img
                            src={item.image}
                            className="aspect-square overflow-hidden rounded bg-gray-100 object-cover"
                          />

                          <button
                            className="absolute inset-0 flex items-center justify-center bg-[#fff]/70 opacity-0 transition-opacity group-hover:opacity-100"
                            onClick={() => remove([item.objectID])}
                          >
                            <XMarkIcon className="h-6 w-6" />
                          </button>
                        </div>

                        <div className="flex flex-1 justify-between">
                          <div className="">
                            <div className="">{item.title}</div>
                            <div className="text-sm">{formatDuration(item.duration, '<1m')}</div>
                          </div>

                          <div>
                            <Small as="div" className="flex-shrink-0">
                              {item.price}
                            </Small>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div className="h-[1px] bg-gray-200" />

                  <div className="flex items-center justify-between">
                    <div className="text-[#6D6D6D]">Total</div>
                    <Small as="div">
                      <Price amount={total} zero="-" />
                    </Small>
                  </div>

                  <Small
                    as={Link}
                    to="/checkout"
                    className="flex h-14 w-full items-center justify-center rounded bg-[#1B1B1B] text-white"
                  >
                    Continue
                  </Small>
                </>
              ) : (
                <Small
                  as={Link}
                  to="/explore"
                  className="flex h-14 w-full items-center justify-center rounded bg-[#1B1B1B] text-white"
                >
                  Browse videos
                </Small>
              )}
            </>
          )}
        </aside>
      </Transition>
    </div>
  );
};

const Header = () => {
  const { context, logout } = Auth.useContainer();
  const { open, items } = Cart.useContainer();

  const match = useMatch('/videos/:videoId');

  return (
    <header className={classnames('relative z-10 mx-auto w-full max-w-7xl p-4 md:px-10', { 'text-white': !!match })}>
      <div className="flex h-20 flex-wrap items-center justify-between">
        <Logo className="sm:flex-1" />

        <nav className="order-last flex w-full justify-evenly py-4 sm:order-none sm:mr-8 sm:w-auto sm:space-x-8 lg:mr-16 lg:space-x-16">
          <NavLink to="/explore">
            {({ isActive }) => (
              <Small
                as="span"
                className={classnames('border-b-2 py-1', isActive ? 'border-[#1B1B1B]/75' : `border-transparent`)}
              >
                Explore
              </Small>
            )}
          </NavLink>

          {(!!context?.contents.length || !!context?.subscription) && (
            <NavLink to="/library">
              {({ isActive }) => (
                <Small
                  as="span"
                  className={classnames('border-b-2 py-1', isActive ? 'border-[#1B1B1B]/75' : `border-transparent`)}
                >
                  My library
                </Small>
              )}
            </NavLink>
          )}

          {!context?.subscription && (
            <NavLink to="/pricing">
              {({ isActive }) => (
                <Small
                  as="span"
                  className={classnames('border-b-2 py-1', isActive ? 'border-[#1B1B1B]/75' : `border-transparent`)}
                >
                  Pricing
                </Small>
              )}
            </NavLink>
          )}
        </nav>

        <div className="relative flex items-center space-x-4">
          <Menu>
            <Menu.Button as={React.Fragment}>
              <button className="inline-flex h-10 w-10 items-center justify-center">
                <UserCircleIcon className="h-6 w-6" />
              </button>
            </Menu.Button>

            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Menu.Items className="absolute right-0 top-full z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Small
                        as={Link}
                        to="/orders"
                        className={classnames(
                          'block px-4 py-3 text-sm',
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        )}
                      >
                        Order history
                      </Small>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <Small
                        as="a"
                        href="mailto:support@urban.io"
                        target="_blank"
                        className={classnames(
                          'block px-4 py-3 text-sm',
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        )}
                      >
                        Support
                      </Small>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <Small
                        as="button"
                        className={classnames(
                          'block w-full px-4 py-3 text-left text-sm',
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        )}
                        onClick={() => logout()}
                      >
                        Log out
                      </Small>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <CartTooltip>
            <button className="inline-flex h-10 min-w-[2.5rem] items-center justify-center" onClick={() => open()}>
              <ShoppingCartIcon className="mr-2 h-6 w-6" />
              <Small as="span">{items.length > 9 ? '9+' : items.length}</Small>
            </button>
          </CartTooltip>
        </div>
      </div>
    </header>
  );
};

export const App: React.FC = () => {
  const { authenticated, context } = Auth.useContainer();

  if (!authenticated) {
    return (
      <div className="md:bg-guest relative flex min-h-screen flex-col bg-cover">
        <header className="mx-auto w-full max-w-7xl px-8 py-2 md:p-10">
          <div className="flex h-20 items-center">
            <Logo />
          </div>
        </header>

        <div className="flex flex-1 flex-col items-center justify-center">
          <div className="w-full bg-white px-8 py-12 md:w-[40rem] md:px-32 md:py-24">
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/forgot-password/reset" element={<ResetPasswordPage />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </div>
        </div>
      </div>
    );
  }

  if (!context) return null;

  return (
    <Cart.Provider>
      <React.Fragment>
        <Header />

        <div className="flex flex-1 flex-col">
          <Routes>
            {!context.subscription && <Route path="/pricing" element={<PricingPage />} />}
            <Route path="/explore" element={<ExplorePage />} />
            <Route path="/library" element={<LibraryPage />} />
            <Route path="/orders" element={<OrderHistoryPage />} />
            <Route path="/videos/:videoId" element={<VideoPage />} />
            <Route path="/checkout/confirmation/subscription" element={<CheckoutConfirmSubscriptionPage />} />
            <Route path="/checkout/confirmation/content" element={<CheckoutConfirmContentPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="*" element={<Navigate to="/explore" />} />
          </Routes>
        </div>
      </React.Fragment>
    </Cart.Provider>
  );
};
