import React from 'react';
import { useAsyncFn } from 'react-use';

import { apiClient } from '@/services/api';

import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { Price } from '@/components/Price';
import { Title } from '@/components/Title';

export const PricingPage: React.FC = () => {
  const [{ loading }, initiate] = useAsyncFn(async (priceId: string) => {
    const { url } = await apiClient.purchase.initiateSubscriptionPurchase({
      requestBody: {
        priceId,
        successUrl: `${window.location.origin}/checkout/confirmation/subscription`,
        failureUrl: window.location.href
      }
    });

    window.location.href = url;
  });

  return (
    <Container className="py-20">
      <main className="md:grid md:grid-cols-3">
        <div className="space-y-4">
          <Title as="h2" size="h3">
            Pricing
          </Title>

          <div>Learn from the best when it comes to hard and soft tissue regeneration.</div>
        </div>

        <ul className="gap-8 md:col-span-2 md:grid md:grid-cols-2">
          <li className="flex flex-col space-y-8 border border-gray-100 p-6">
            <Title as="h3" size="h3">
              Plus
            </Title>

            <div className="flex items-end">
              <Title as="p" size="h4">
                <Price amount={3900} />
              </Title>
              /year
            </div>

            <ul className="flex-1">
              <li>4 videos of your choice</li>
              <li>unlimited rewatch</li>
              <li>4k resolution</li>
              <li>voiceover by Dr. Urban Istvan</li>
            </ul>

            <Button
              appearance="primary"
              className="h-14 w-full"
              disabled={loading}
              onClick={() => initiate('price_1N4rciDEtYhPdqIOpsqx2rGP')}
            >
              Subscribe
            </Button>
          </li>

          <li className="flex flex-col space-y-8 border border-gray-100 p-6">
            <Title as="h3" size="h4">
              Premium
            </Title>

            <div className="flex items-end">
              <Title as="p" size="h4">
                <Price amount={6900} />
              </Title>
              /year
            </div>

            <ul className="flex-1">
              <li>unlimited videos</li>
              <li>unlimited rewatch</li>
              <li>4k resolution</li>
              <li>voiceover by Dr. Urban Istvan</li>
              <li>ask directly</li>
            </ul>

            <Button
              appearance="primary"
              className="h-14 w-full"
              disabled={loading}
              onClick={() => initiate('price_1N4rciDEtYhPdqIOpsqx2rGP')}
            >
              Subscribe
            </Button>
          </li>
        </ul>
      </main>
    </Container>
  );
};
