import { CheckIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import React from 'react';

export interface Props {
  active?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?(): void;
}

export const SelectItem = React.forwardRef<HTMLLIElement, React.PropsWithChildren<Props>>(
  ({ active, isSelected, disabled, className, onClick, children, ...rest }, ref) => (
    <li
      {...rest}
      {...{ ref }}
      className={classnames(
        `group relative select-none py-2 pl-4 pr-4`,
        active ? 'bg-[#1B1B1B] text-white' : isSelected && 'bg-gray-100',
        disabled ? `cursor-default` : `cursor-pointer`,
        className
      )}
      onClick={disabled ? undefined : onClick}
    >
      <span className="block truncate">{children}</span>

      {isSelected && (
        <span className={classnames(`absolute inset-y-0 right-0 flex items-center pr-4`, !!active && 'text-white')}>
          <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      )}
    </li>
  )
);
