import { SearchClient as AlgoliaSearchClient } from 'algoliasearch/lite';
import { AlgoliaQueryResolver } from './queries/algolia-query';

export interface SearchClientOptions {
  algolia: AlgoliaSearchClient;
}

export class SearchClient {
  constructor(protected options: SearchClientOptions) {}

  async search<Q extends Record<string, AlgoliaQueryResolver<any>>>(
    queries: Q
  ): Promise<{ [x in keyof Q]: ReturnType<Q[x]['handler']> }> {
    const entries = Object.entries(queries);

    const { results } = await this.options.algolia.search(entries.map(([, { query }]) => query));

    return entries.reduce((data, [key, { handler }], index) => {
      data[key as keyof Q] = handler(results[index]);
      return data;
    }, {} as { [x in keyof Q]: ReturnType<Q[x]['handler']> });
  }
}
