import React from 'react';
import classnames from 'classnames';

interface Props {
  className?: string;
}

export const Tags: React.FC<React.PropsWithChildren<Props>> = ({ className, children }) => (
  <ul className={classnames('flex', className)}>
    {React.Children.toArray(children).reduce<React.ReactNode>(
      (previous, children, index) => (
        <React.Fragment>
          {previous}
          {index > 0 && <li className="px-2">•</li>}
          <li>{children}</li>
        </React.Fragment>
      ),
      null
    )}
  </ul>
);
