import React from 'react';
import { Link } from 'react-router-dom';

import { Small } from './Small';

interface Props {
  className?: string;
}

export const Logo: React.FC<Props> = ({ className }) => (
  <Small as={Link} to="/" {...{ className }}>
    <div>Urban</div>
    <div>Digital</div>
    <div>Education</div>
  </Small>
);
