import qs from 'query-string';
import React from 'react';
import { Navigate } from 'react-router';

import { Cart } from '@/containers/useCart';
import { apiClient } from '@/services/api';

import { Container } from '@/components/Container';
import { Loading, LoadingIndicator, LoadingMessage } from '@/components/Loading';
import { Small } from '@/components/Small';

export const CheckoutPage: React.FC = () => {
  const cart = Cart.useContainer();

  React.useEffect(() => {
    const ids = cart.items.map((item) => item.objectID);

    if (!ids.length) return;

    apiClient.purchase
      .initiateContentPurchase({
        requestBody: {
          content: ids,
          successUrl: qs.stringifyUrl({
            url: `${window.location.origin}/checkout/confirmation/content`,
            query: { contentIds: ids }
          }),
          failureUrl: `${window.location.origin}/explore`
        }
      })
      .then(({ url }) => (window.location.href = url))
      .catch(() => null);
  }, [cart]);

  if (!cart.loading && !cart.items.length) return <Navigate to="/explore" replace />;

  return (
    <Container className="flex flex-1 items-center justify-center">
      <main className="pt-20 pb-40 text-center">
        <Loading visible>
          <LoadingMessage>
            <LoadingIndicator className="h-6 w-6" />
            <Small as="div">Loading...</Small>
          </LoadingMessage>
        </Loading>
      </main>
    </Container>
  );
};
