import React from 'react';
import { useLocalStorage, usePrevious } from 'react-use';
import { createContainer } from 'unstated-next';

const useLastSession = () => {
  const [lastSession, setLastSession] = useLocalStorage<Date | undefined>('last-session', undefined, {
    raw: false,
    serializer: (value) => (value ? value.toISOString() : ''),
    deserializer: (value) => (value ? new Date(value) : undefined)
  });

  const previous = usePrevious(lastSession);

  React.useEffect(() => {
    const timeout = setTimeout(() => setLastSession(new Date()), 1000 * 60 * 5);

    return () => clearTimeout(timeout);
  }, []);

  return previous;
};

export const LastSession = createContainer(useLastSession);
