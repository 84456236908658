/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingPortalResponse } from '../models/BillingPortalResponse';
import type { InitiateContentPurchaseRequest } from '../models/InitiateContentPurchaseRequest';
import type { InitiateContentPurchaseResponse } from '../models/InitiateContentPurchaseResponse';
import type { InitiateSubscriptionPurchaseRequest } from '../models/InitiateSubscriptionPurchaseRequest';
import type { InitiateSubscriptionPurchaseResponse } from '../models/InitiateSubscriptionPurchaseResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PurchaseService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieves the billing portal url.
   * @returns BillingPortalResponse Redirect url of to continue the purchase flow.
   * @throws ApiError
   */
  public getBillingPortal({
    redirectUrl,
  }: {
    redirectUrl: string,
  }): CancelablePromise<BillingPortalResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/billing',
      query: {
        'redirectUrl': redirectUrl,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Initiates content purchase.
   * @returns InitiateContentPurchaseResponse Redirect url of to continue the purchase flow.
   * @throws ApiError
   */
  public initiateContentPurchase({
    requestBody,
  }: {
    requestBody: InitiateContentPurchaseRequest,
  }): CancelablePromise<InitiateContentPurchaseResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/billing/purchases/contents',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        409: `One or more content are already purchased.`,
      },
    });
  }

  /**
   * Initiates a subscription purchase.
   * @returns InitiateSubscriptionPurchaseResponse Redirect url of to continue the purchase flow.
   * @throws ApiError
   */
  public initiateSubscriptionPurchase({
    requestBody,
  }: {
    requestBody: InitiateSubscriptionPurchaseRequest,
  }): CancelablePromise<InitiateSubscriptionPurchaseResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/billing/purchases/subscriptions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        409: `There is an active subscription active already.`,
      },
    });
  }

}
