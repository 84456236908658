import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { ValidationMessage } from '@/components/Form/ValidationMessage';
import { Link } from '@/components/Link';
import { Loading, LoadingIndicator } from '@/components/Loading';
import { LoadingButton } from '@/components/LoadingButton';
import { Auth } from '@/containers/useAuth';
import { useSearchParams } from 'react-router-dom';

export const schema = yup
  .object({
    password: yup.string().required().label(translations.fields.password.label),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password')], translations.customValidation.samePassword)
      .required()
      .label(translations.fields.repeatPassword.label)
  })
  .required();

export const ResetPasswordPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const { login } = Auth.useContainer();

  const token = searchParams.get('token');

  const { data, isLoading: loading } = useSWR(
    token ? { key: `useResetPasswordToken`, token } : null,
    () => Promise.resolve({ email: 'a@a.com' }),
    { revalidateOnMount: true, revalidateIfStale: true, revalidateOnFocus: false, revalidateOnReconnect: false }
  );

  const onSubmit = React.useCallback(
    (values: yup.InferType<typeof schema>) => {
      if (!token || !data?.email) return 'The token is invalid.';

      return login({ email: data.email, password: values.password });
    },
    [token, data, login]
  );

  if (loading) {
    return (
      <div className="relative h-6">
        <Loading visible className="absolute inset-0 flex items-center justify-center">
          <LoadingIndicator className="h-6 w-6" />
        </Loading>
      </div>
    );
  }

  return (
    <Form {...{ onSubmit, schema }}>
      {({ submitting, submitError }) => (
        <div className="space-y-10">
          <h1 className="text-3xl font-light md:text-4xl">
            <FormattedMessage id={translations.pages.resetPassword.title} />
          </h1>

          {!token || !data?.email ? (
            <>
              <div>
                <FormattedMessage id={translations.pages.resetPassword.invalid.message} />
              </div>

              <Button
                as={RouterLink}
                appearance="primary"
                to="/forgot-password"
                className="flex h-14 items-center justify-center"
                repalce
              >
                <FormattedMessage id={translations.pages.resetPassword.invalid.cta} />
              </Button>
            </>
          ) : (
            <>
              <div>
                <FormattedMessage id={translations.pages.resetPassword.reset.message} />
              </div>

              <div className="space-y-6">
                <ValidatedField
                  field={InputWithLabel}
                  id="password"
                  label={<FormattedMessage id={translations.fields.password.label} />}
                  placeholder={formatMessage({ id: translations.fields.password.placeholder })}
                  name="password"
                  readOnly={submitting}
                  type="password"
                />

                <ValidatedField
                  field={InputWithLabel}
                  id="repeat-password"
                  label={<FormattedMessage id={translations.fields.repeatPassword.label} />}
                  placeholder={formatMessage({ id: translations.fields.repeatPassword.placeholder })}
                  name="repeatPassword"
                  readOnly={submitting}
                  type="password"
                />

                <ValidationMessage visible={!!submitError}>{submitError}</ValidationMessage>

                <LoadingButton
                  appearance="primary"
                  className="h-14 w-full"
                  loading={submitting}
                  disabled={loading}
                  type="submit"
                >
                  <FormattedMessage id={translations.pages.resetPassword.reset.cta} />
                </LoadingButton>

                <div className="text-center">
                  <Link as={RouterLink} to="/login">
                    <FormattedMessage id={translations.pages.resetPassword.reset.remember} />
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Form>
  );
};
