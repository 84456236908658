import React from 'react';
import { useLocalStorage } from 'react-use';
import useSWR from 'swr';
import { createContainer } from 'unstated-next';

import { apiClient } from '@/services/api';
import { CancelablePromise, Session } from '@urban/platform-api-sdk';
import { ApiRequestOptions } from '@urban/platform-api-sdk/src/core/ApiRequestOptions';

const apiClientRequest = apiClient.request.request.bind(apiClient.request);

const useApiClient = (session?: Session) => {
  const ref = React.useRef<Promise<void>>(null);

  apiClient.request.request = <T>(options: ApiRequestOptions): CancelablePromise<T> => {
    return apiClientRequest<T>(options).catch(async (error) => {
      console.log({ status: error.status });

      if (error?.status !== 401 || !session?.refreshToken) throw error;

      ref.current =
        ref.current ||
        apiClient.authentication
          .renew({ requestBody: { refreshToken: session.refreshToken } })
          .then((renewedSession) => {
            ref.current = null;

            setSession(renewedSession);
          })
          .finally(() => {
            ref.current = null;
          });

      await ref.current!;

      return apiClientRequest(options);
    });
  };

  apiClient.request.config.TOKEN = session?.accessToken;

  return apiClient;
};

const useAuth = () => {
  const [session, setSession, clearSession] = useLocalStorage<Session | undefined>('session', undefined, {
    raw: false,
    serializer: JSON.stringify,
    deserializer: (value) => {
      try {
        const parsed = JSON.parse(value) as Partial<Session>;
        return parsed.accessToken && parsed.refreshToken ? (parsed as Session) : undefined;
      } catch {
        return undefined;
      }
    }
  });

  const apiClient = useApiClient(session);

  const {
    data: context,
    isLoading,
    isValidating,
    mutate
  } = useSWR(session ? { key: `useAuth`, accessToken: session.accessToken } : null, () => apiClient.user.get(), {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  const authenticated = !!session;
  const loading = isLoading || isValidating;

  return React.useMemo(
    () => ({
      authenticated,
      loading,
      context,
      login: (o: { email: string; password: string }) =>
        apiClient.authentication.loginWithPassword({ requestBody: o }).then(setSession),
      reload: () => mutate(),
      logout: async () => {
        const refreshToken = session?.refreshToken;

        if (!refreshToken) return clearSession();

        await apiClient.authentication.logout({ requestBody: { refreshToken } });

        clearSession();
      }
    }),
    [authenticated, loading, context, session, setSession, clearSession, mutate]
  );
};

export const Auth = createContainer(useAuth);
